@font-face {
  font-family: 'Manrope';
  src:
    url('Fonts/manrope/Manrope-Regular.woff2') format('woff2');
  font-style: normal;
}

body {
  font-family: Manrope, sans-serif;
}

a {
  color: red;
  text-decoration: none;

  &.link {
    color: #1993ff;
  }

  &:hover {
    text-decoration: underline;
    color: #737373;
  }
}

h1 {
  text-align: center;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 1.1rem;
  color: #6c757d;
  white-space: nowrap;
}

.teaser {
  color: #737373;
  text-align: center;
  font-size: 18px;
}

.copyleft {
  &:before {
    content: '\00a9';
  }

  font-size: 18px;
  display: inline-block;
  transform: scaleX(-1);
}